<template>
  <div id="home-hero">
    <div class="hero-text">
      <img
        src="/static/images/hero/hero-bg-display.png"
        class="bg-display-image"
        alt=""
      />
      <div class="t-m">
        <div class="by-fabric">
          by Fabrictienda
          <img src="/static/logos/fabrictienda-minilogo.png" alt="" />
        </div>
        <h1>
          Mobiliario apto intemperie
          <br />
          <div class="separator"></div>
          <div class="smaller">para hoy y para siempre</div>
        </h1>
        <p class="subtitle">Fabricantes de mobiliario outdoor exclusivo</p>
        <div class="mos-btn" @click="scrollTo('home-ourproducts')">
          Ver Productos
        </div>
      </div>
    </div>
    <div
      class="hero-img"
      style="background-image: url('/static/images/hero/tulum-hero.jpeg')"
    ></div>
  </div>
</template>
<script>
export default {
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style scoped lang="scss">
#home-hero {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: $color-quinary;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    height: unset;
    background-color: unset;
  }

  .hero-text {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 75px 10% 0 10%;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: unset;
      padding: 1.5rem 7%;
    }

    .bg-display-image {
      position: absolute;
      left: 0;
      top: 20px;
      width: 50%;
      height: auto;
      z-index: 99;

      @media (max-width: 767px) {
        left: auto;
        right: 0;
        top: 0;
        width: 40%;
        transform: scaleX(-1);
      }
    }

    .t-m {
      position: relative;
      z-index: 999;

      .by-fabric {
        font-weight: 600;
        color: $color-primary;
        display: flex;
        align-items: baseline;
        margin-bottom: 0.5rem;
        font-size: 20px;

        @media (max-width: 767px) {
          font-size: 15px;
        }

        img {
          height: 30px;
          width: auto;
          margin-left: 0.5rem;

          @media (max-width: 767px) {
            height: 22px;
          }
        }
      }
      h1 {
        font-weight: 600;
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
          font-weight: 600;
          font-size: 30px;
          margin-bottom: 1rem;
          line-height: 38px;
        }
        .separator {
          @media (max-width: 767px) {
            height: 5px;
            width: 100%;
          }
        }

        .smaller {
          font-size: 34px;
          font-weight: 400;
          line-height: 50px;

          @media (max-width: 767px) {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      .subtitle {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
          font-weight: 400;
          font-size: 15px;
          margin-bottom: 1.5rem;
        }
      }
      .mos-btn {
        z-index: 999;
        @media (max-width: 767px) {
          width: 100%;
          justify-content: center;
          padding: 0.75rem;
        }
      }
    }
  }

  .hero-img {
    width: 40%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      width: 100%;
      height: 420px;
    }
  }
}
</style>
