<template>
  <div id="home-tendencias">
    <section class="sc-main">
      <h3 class="mb-3 mb-md-2">Tendencias en Instagram</h3>

      <a :href="'https://www.instagram.com/' + $instagram" class="mos-btn">
        <i class="fa-brands fa-instagram"></i>tulumoutdoor
      </a>
      <InstagramFeed
        :count="!isMobile ? 6 : 3"
        accessToken="IGQWRPUWtnd1gwRDh4WjJSS2ltWDN5S2xSS0VjUTM0c2gtSlNUVGxsYlhsbW1kamN6RmtnN01JOXpqU2ZAEQWRHaDRPRklpTC1vWDl1b0FnUE9YQ3pyNmVGdzJuV1lYX21US2h2dmJ5aEZA0ZAwZDZD"
        :caption="true"
      />
    </section>
  </div>
</template>
<script>
import mobileChecker from '@/mixins/mobileChecker'

export default {
  mixins: [mobileChecker]
}
</script>
<style scoped lang="scss">
#home-tendencias {
  width: 100%;
  background-color: $color-quinary;

  .instagram-wrapper {
    padding: 0;
  }
  .instagram-gallery-item {
    aspect-ratio: 1/1;

    @media (max-width: 576px) {
      flex: 1 0 21rem !important;
    }
  }

  .sc-main {
    width: 100%;
    padding: 5rem 10%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      padding: 4rem 7%;
    }

    h3 {
      font-weight: 600;
      text-align: center;
    }

    .mos-btn {
      i {
        font-size: 22px;
        margin-right: 0.5rem;
      }

      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
      }
    }
  }
}
</style>
