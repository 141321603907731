<template>
  <div id="article-page">
    <div class="sc-main">
      <ArticleGalleryComp v-if="GalleryItem" :items="GalleryItem" />
      <ArticleDataComp v-if="ArticleItem" :item="ArticleItem" />
    </div>
  </div>
</template>
<script>
import ArticleDataComp from './components/ArticleDataComp.vue';
import ArticleGalleryComp from './components/ArticleGalleryComp.vue';
import Productos from '@/data/articles.json';
import Imagenes from '@/data/galleries.json';

export default {
  data() {
    return {
      articulos: Productos.data,
      galleries: Imagenes.data,
      product_id: null,
    };
  },
  components: {
    ArticleDataComp,
    ArticleGalleryComp,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.product_id = parseInt(this.$route.params.nombre.split('_')[0], 10);
  },
  computed: {
    ArticleItem: function () {
      return this.articulos.find((x) => x.id === this.product_id);
    },
    GalleryItem: function () {
      return this.galleries.filter((x) => x.product_id === this.product_id);
    },
  },
};
</script>
<style scoped lang="scss">
#article-page {
  padding: 8rem 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 5rem 0;
  }

  .sc-main {
    width: 100%;
    display: flex;
    padding: 0 10%;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0;
    }
  }
}
</style>
