<template>
  <div id="socialmedia">
    <a
      v-if="link_instagram"
      target="_blank"
      class="social-media-item instagram"
      :href="'https://www.instagram.com/' + link_instagram"
    >
      <i class="fa-brands fa-instagram"></i>
    </a>
    <a
      v-if="link_whatsapp"
      target="_blank"
      :href="link_whatsapp"
      class="social-media-item whatsapp"
    >
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      link_instagram: null,
      link_whatsapp: null,
    };
  },
  mounted() {
    this.link_instagram = this.$instagram;
    this.link_whatsapp = this.$whatsapp;
  },
};
</script>
<style scoped lang="scss">
#socialmedia {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    justify-content: flex-end;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .social-media-item {
    height: 40px;
    width: 40px;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    text-decoration: none;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-left: 0.75rem;
    }
    @media (min-width: 768px) {
      z-index: 999;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    &:hover {
      background-color: $color-secondary !important;
    }

    &.instagram {
      background-color: $color-instagram;
    }

    &.whatsapp {
      background-color: $color-whatsapp;
    }

    i {
      font-size: 1.25rem;
      color: $white;
    }
  }
}
</style>
