<template>
  <div id="article-data-comp">
    <section class="sc-main">
      <h3 class="mb-4">{{ item.name }}</h3>
      <p class="mb-4">{{ item.description }}</p>
      <a class="mos-btn" :href="item.wplink" target="_blank"
        >Obtener cotización</a
      >
    </section>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
<style scoped lang="scss">
#article-data-comp {
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 7%;
  }

  .sc-main {
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      font-weight: 600;
    }
    .mos-btn {
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
      }
    }
  }
}
</style>
