<template>
  <div id="topbar" :class="scrollPosition > 200 ? 'scrolled' : ''">
    <div class="containdata">
      <ul class="menu">
        <li><router-link :to="{ name: 'home' }"> Home </router-link></li>
        <li>
          <router-link :to="{ name: 'home', query: { section: 'home-ourproducts' } }">
            Productos
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', query: { section: 'home-tendencias' } }">
            Tendencias
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', query: { section: 'home-contactus' } }">
            Contacto
          </router-link>
        </li>
      </ul>
      <ul class="brand">
        <li>
          <a @click="goHome">
            <img src="/static/logos/tulum-logo-topbar.png" alt="" />
          </a>
        </li>
      </ul>
      <ul class="social">
        <li>
          <a v-if="link_whatsapp" class="link-whatsapp" target="_blank" :href="link_whatsapp">
            <i class="fa-brands fa-whatsapp"></i>
          </a>
        </li>
        <li>
          <a
            v-if="link_instagram"
            class="link-instagram"
            target="_blank"
            :href="'https://www.instagram.com/' + link_instagram"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollPosition: null,
      link_instagram: null,
      link_whatsapp: null
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    this.link_instagram = this.$instagram
    this.link_whatsapp = this.$whatsapp
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    goHome() {
      if (this.$router.currentRoute.value.name == 'home') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
<style scoped lang="scss">
#topbar {
  z-index: 9999;
}
</style>
