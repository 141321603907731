<template>
  <nav id="topbar-mobile-menu">
    <ul>
      <li>
        <a @click="goLink('home')"
          >Home <i class="fa-solid fa-chevron-right"></i
        ></a>
      </li>
      <li>
        <a @click="goLink('home', 'home-ourproducts')"
          >Productos <i class="fa-solid fa-chevron-right"></i
        ></a>
      </li>
      <li>
        <a @click="goLink('home', 'home-tendencias')"
          >Tendencias <i class="fa-solid fa-chevron-right"></i
        ></a>
      </li>
      <li>
        <a @click="goLink('home', 'home-contactus')"
          >Contacto <i class="fa-solid fa-chevron-right"></i
        ></a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  methods: {
    closeMenu() {
      setTimeout(() => this.$emit('closeMenu'), 400);
    },
    goLink(link, sec) {
      this.$router.push({ name: link, query: { section: sec } });
      this.closeMenu();
    },
  },
};
</script>
