<template>
  <div id="home-ourproducts">
    <section class="sc-main">
      <h3 class="mb-4 mb-md-5">Nuestros Productos</h3>
      <div class="sc-wrapper">
        <CardProductHomeComp
          v-for="item in articulos"
          :key="item.id"
          :item="item"
        />
      </div>
    </section>
  </div>
</template>
<script>
import CardProductHomeComp from 'Components/global/cards/CardProductHomeComp.vue';
import Productos from '@/data/articles.json';

export default {
  data() {
    return {
      articulos: Productos.data,
    };
  },
  components: {
    CardProductHomeComp,
  },
};
</script>
<style scoped lang="scss">
#home-ourproducts {
  width: 100%;

  .sc-main {
    width: 100%;
    padding: 5rem 10%;

    @media (max-width: 767px) {
      padding: 0;
    }

    .sc-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  h3 {
    font-weight: 600;
    text-align: center;
  }
}
</style>
