<template>
  <TopbarMobile v-if="isMobile || isTablet" />
  <Topbar v-else />
  <div class="content">
    <router-view :key="$route.fullPath" />
  </div>
  <Footer />
</template>

<script>
import Topbar from 'Components/topbar/Topbar.vue';
import TopbarMobile from 'Components/topbar/topbarmobile/TopbarMobile.vue';
import Footer from 'Components/footer/Footer.vue';
import mobileChecker from '@/mixins/mobileChecker';

export default {
  mixins: [mobileChecker],
  components: {
    Topbar,
    TopbarMobile,
    Footer,
  },
};
</script>

<style lang="scss">
.content {
  min-height: 100vh;
  background-color: $color-tertiary;

  @media (max-width: 767px) {
    background-color: $color-quinary;
  }
}
</style>
