<template>
  <div id="home-aboutus">
    <section class="sc1 py-3 py-md-5">
      <div class="sc-cont">
        <img class="mb-4" src="/static/logos/logo-image-black.png" alt="" />
        <h3 class="mb-3 mb-md-4">Que Ofrecemos</h3>
        <p>
          En Tulumoutdoor damos a conocer una colección exclusiva de mobiliario
          exterior apto intemperie con los mejores componentes y la mejor
          relación precio / calidad del mercado actual by FabricTienda
        </p>
      </div>
    </section>
    <section class="sc2 sc-5050 py-4 py-md-5">
      <div class="sc-cont">
        <div class="sc-text">
          <h4 class="mb-3 mb-md-4">Compromiso y dedicación</h4>
          <p>
            Estamos abocados en elaborar mobiliario de primera calidad para
            satisfacer los estándares más altos cuidando hasta el último
            detalle. Buscamos que al utilizar cada producto sientan toda la
            dedicación y el compromiso que ponemos en nuestro trabajo.
          </p>
        </div>
        <div
          class="sc-img"
          style="
            background-image: url('/static/images/aboutus/tulum-aboutus1.jpg');
          "
        ></div>
      </div>
    </section>
    <section class="sc3 sc-5050 py-4 py-md-5">
      <div class="sc-cont">
        <div class="sc-text">
          <h4 class="mb-3 mb-md-4">Personalizamos tu necesidad</h4>
          <p>
            Diseñamos mobiliario completamente personalizado. Podrán solicitar
            todo a medida para que cada juego de exterior encaje a la perfección
            y resalte cualquier ambiente.
          </p>
        </div>
        <div
          class="sc-img scnd-img"
          style="
            background-image: url('/static/images/aboutus/tulum-aboutus2.jpeg');
          "
        >
          <img src="/static/logos/tulum-vertical-custom.png" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#home-aboutus {
  width: 100%;
  padding: 6rem 0;

  @media (max-width: 767px) {
    padding: 2rem 7%;
  }

  .sc1 {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      align-items: center;
    }

    .sc-cont {
      max-width: 35%;
      text-align: center;

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 85%;
      }
    }

    img {
      width: 200px;
      height: auto;

      @media (max-width: 767px) {
        width: 150px;
      }
    }

    h3 {
      font-weight: 600;
    }
  }

  .sc-5050 {
    width: 100%;
    display: flex;

    .sc-cont {
      width: 100%;
      display: flex;
      height: 450px;
      align-items: center;

      @media (max-width: 767px) {
        height: unset;
        flex-direction: column-reverse;
        flex-wrap: wrap;
      }

      .sc-text {
        width: 50%;
        height: 70%;
        background-color: $color-quinary;
        padding: 0 10%;
        display: flex;
        align-content: center;
        flex-wrap: wrap;

        @media (max-width: 767px) {
          height: unset;
          padding: 1rem 0;
          width: 100%;
          background-color: unset;
        }
      }

      .sc-img {
        width: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        @media (max-width: 767px) {
          height: 200px;
          width: 100%;
        }

        &.scnd-img {
          position: relative;

          img {
            position: absolute;
            height: 180px;
            width: auto;
            bottom: 10px;
            right: 10px;

            @media (max-width: 767px) {
              height: 90px;
              right: 5px;
              bottom: 5px;
            }
          }
        }
      }
    }

    &.sc3 {
      .sc-cont {
        flex-direction: row-reverse;

        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
</style>
