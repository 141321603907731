<template>
  <div
    id="card-product-home-comp"
    v-if="item"
    :class="item.side == 'left' ? 'side-left' : ''"
  >
    <div class="card-text">
      <router-link :to="item.url"
        ><h3 class="mb-3 mb-md-4">{{ item.name }}</h3></router-link
      >
      <p class="mb-4">{{ item.desc_short }}</p>
      <router-link :to="item.url" class="more-btn">
        <a> Ver Productos <i class="fa-solid fa-chevron-right"></i> </a>
      </router-link>
    </div>
    <div
      class="card-img"
      :style="{ 'background-image': 'url(' + item.image_path + ')' }"
    ></div>
  </div>
</template>
<script>
import mobileChecker from '@/mixins/mobileChecker';

export default {
  mixins: [mobileChecker],
  props: ['item'],
};
</script>
<style scoped lang="scss">
#card-product-home-comp {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }

  &.side-left {
    flex-direction: row-reverse;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .card-text {
    width: 50%;
    height: 400px;
    background-color: $color-quinary;
    padding: 3rem 4rem;

    > a {
      text-decoration: none;
      width: fit-content;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: unset;
      padding: 2rem 7%;
    }

    .more-btn {
      height: 50px;
      display: flex;
      align-items: center;
      a {
        color: $color-primary;
        font-size: 18px;
        cursor: pointer;
        font-weight: 400;
        transition: all 0.3s ease-in-out;

        @media (max-width: 767px) {
          font-size: 16px;

          i {
            font-size: 14px;
          }
        }

        &:hover {
          i {
            transform: translate(10px, 0);
          }
        }

        i {
          margin-left: 0.5rem;
          font-size: 15px;
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }
  .card-img {
    width: 50%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
