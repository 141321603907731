import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/home/Home.vue';
import Article from '../pages/article/Article.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/article/:nombre',
    name: 'article',
    component: Article,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  document.getElementById('app').scrollTop = 0;
});

export default router;
