import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import InstagramFeed from 'vue3-instagram-feed'
import 'vue3-instagram-feed/dist/style.css'

const app = createApp(App)
app.config.globalProperties.$instagram = 'tulumoutdoor'
app.config.globalProperties.$whatsapp = 'https://wa.me/541139347665'
app.use(store).use(router).use(InstagramFeed).mount('#app')
