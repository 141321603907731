<template>
  <div id="topbar-mobile" :class="scrollPosition > 200 ? 'scrolled' : ''">
    <nav class="top-nav">
      <ul class="menu-btn">
        <li>
          <a @click="menuToggle()">
            <i class="fa-solid fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul class="brand">
        <li>
          <a @click="goHome()">
            <img src="/static/logos/tulum-logo-topbar.png" alt="" />
          </a>
        </li>
      </ul>
      <ul class="social">
        <li>
          <a v-if="link_whatsapp" target="_blank" :href="link_whatsapp">
            <i class="fa-brands fa-whatsapp"></i>
          </a>
        </li>
        <li>
          <a
            v-if="link_instagram"
            target="_blank"
            :href="'https://www.instagram.com/' + link_instagram"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>
        </li>
      </ul>
    </nav>
    <TopbarMobileMenu
      @closeMenu="showMenu = false"
      :class="showMenu ? 'menu-open' : ''"
    />
    <div class="menu-overlay" :class="showMenu ? 'overlay-open' : ''"></div>
  </div>
</template>
<script>
import TopbarMobileMenu from './TopbarMobileMenu.vue';

export default {
  data() {
    return {
      showMenu: false,
      scrollPosition: null,
      link_instagram: null,
      link_whatsapp: null,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    this.link_instagram = this.$instagram;
    this.link_whatsapp = this.$whatsapp;
  },
  components: {
    TopbarMobileMenu,
  },
  methods: {
    menuToggle() {
      this.showMenu = !this.showMenu;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    goHome() {
      if (this.$router.currentRoute.value.name == 'home') {
        window.scrollTo(0, 0);
      } else {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>
