<template>
  <div id="home-page">
    <HeroComp />
    <AboutUsComp />
    <OurProductsComp />
    <TendenciasComp />
    <ContactUsComp />
  </div>
</template>
<script>
import HeroComp from './components/HeroComp.vue';
import AboutUsComp from './components/AboutUsComp.vue';
import OurProductsComp from './components/OurProductsComp.vue';
import TendenciasComp from './components/TendenciasComp.vue';
import ContactUsComp from './components/ContactUsComp.vue';

export default {
  mounted() {
    if (this.$route.query.section) {
      this.scrollTo(this.$route.query.section);
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  components: {
    HeroComp,
    AboutUsComp,
    OurProductsComp,
    TendenciasComp,
    ContactUsComp,
  },
};
</script>
<style scoped lang="scss">
#home-page {
}
</style>
