<template>
  <div id="home-contactus">
    <h3 class="mb-4">Contactanos</h3>
    <p class="mb-4">
      Hablanos para pedir mas informacion acerca de nuestros productos
    </p>
    <a :href="$whatsapp" class="mos-btn-square">Hablanos al WhatsApp</a>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#home-contactus {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $color-quaternary;
  padding: 5rem;

  @media (max-width: 767px) {
    padding: 3rem 10%;
    text-align: center;
  }

  h3 {
    font-weight: 400;
    color: $color-tertiary;
  }
  p {
    color: $color-tertiary;
  }
}
</style>
