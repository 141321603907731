<template>
  <div id="footer">
    <div class="main-container">
      <div class="row">
        <div class="col-12 col-md-4 comdata">
          <div class="row">
            <div class="col-6 col-md-12 mb-4">
              <div class="brand">
                <img src="/static/logos/logo-horizontal.png" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-12">
              <SocialmediaComponent />
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3 site-info">
          <div class="sec-title">Informacion</div>
          <ul>
            <li><router-link :to="{ name: 'home' }">Home</router-link></li>
            <li>
              <router-link :to="{ name: 'home', query: { section: 'home-aboutus' } }"
                >Sobre Nosotros</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'home', query: { section: 'home-ourproducts' } }"
                >Productos</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'home', query: { section: 'home-tendencias' } }"
                >Tendencias</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'home', query: { section: 'home-contactus' } }"
                >Contacto</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 mediosdepago">
          <div class="sec-title">Medios de Pago:</div>
          <div class="row">
            <div class="col-12 mediodepago">
              <i class="fa-solid fa-money-bill"></i>
              <span>Efectivo</span>
            </div>
            <div class="col-12 mediodepago">
              <i class="fa-solid fa-wallet"></i>
              <span>Deposito o Transferencia Bancaria</span>
            </div>
          </div>
          <h5 class="mt-md-3">Envíos a todo el país</h5>
        </div>
      </div>
      <div class="sec-copyrights">
        <div class="copy-txt">© 2023 Tulumoutdoor. All Rights Reserved.</div>
        <div class="yivo-sec">
          <span>Website made in</span>
          <a href="https://yivocode.com" target="_blank">
            <img src="/static/logos/yivo-logo.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SocialmediaComponent from 'Components/global/SocialmediaComponent.vue'

export default {
  components: {
    SocialmediaComponent
  }
}
</script>
<style scoped lang="scss">
#footer {
}
</style>
